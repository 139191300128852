import ReactRailsUJS from 'react_ujs';

let allImports = {};

/**
 * Takes an `import.meta.glob("/glob/to/components", { eager: true })` value (an eager glob import) and initializes
 * React-Rails with the component files returned by that eager glob import.
 *
 * @param importMetaGlob - an `import.meta.glob(..., { eager: true })` value, **not** a glob string.
 */
export function initializeReactRails(importMetaGlob: Record<string, any>) {
	allImports = { ...allImports, ...importMetaGlob };
	ReactRailsUJS.getConstructor = viteConstructorRequireContext(allImports);
}
/**
 * Given a path like...
 * - `'../../javascript/react/webtext_components/PageElements/index.tsx'`
 * - or `'../../javascript/react/webtext_components/PageElements.tsx'`,
 *
 * and a componentName like `PageElements`, does the path match the componentName?
 */
function doesPathMatchComponentName(path: string, componentName: string): boolean {
	return path.endsWith(`/${componentName}.tsx`) || path.endsWith(`/${componentName}/index.tsx`);
}

/**
 * Custom constructor in order to make Vite glob imports work with React-Rails-UJS;
 * @see {@link https://github.com/reactjs/react-rails/issues/1134#issuecomment-1415112288}
 * @see {@link https://github.com/reactjs/react-rails/issues/1134#issuecomment-1803036505}
 */
function viteConstructorRequireContext(reqCtx: {
	[path: string]: any /* a map of path strings to Modules */;
}) {
	const paths = Object.keys(reqCtx);

	return (componentName: string) => {
		// Load the module:
		const componentPath = paths.find((path) => doesPathMatchComponentName(path, componentName));
		if (!componentPath) {
			throw new Error(`Could not find component named: ${componentName}`);
		}
		return reqCtx[componentPath].default;
	};
}
